import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import { NotificationsList } from "../notifications-list";

const ScrollableNotificationsList = ({
  notifications,
  fetchNext,
  hasMore,
  onClose,
}) => {
  return (
    <InfiniteScroll
      initialLoad={false}
      loadMore={fetchNext}
      hasMore={hasMore}
      useWindow={false}
      threshold={100}
    >
      <NotificationsList
        notifications={notifications}
        handleCloseDrawer={onClose}
      />
    </InfiniteScroll>
  );
};

export { ScrollableNotificationsList };
