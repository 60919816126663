import { Dropdown, Flex } from "antd";
import React, { useMemo } from "react";
import { BsThreeDots } from "react-icons/bs";

import { RemoveModal } from "../../ui/modals/remove-modal";
import { Votes } from "../../ui/votes";
import { COMMENTS_ACTION_TYPES, toolbarActions } from "../constants";
import styles from "./comments-actions.module.scss";

const CommentsActions = ({
  onAction,
  votesCount,
  activeVote,
  comments_count,
  permissions,
}) => {
  const [removeModalVisible, setRemoveModalVisible] = React.useState(false);
  const dropdownItems = useMemo(() => {
    return [
      {
        key: "edit",
        label: "Редагувати",
        visible: permissions.can_edit,
      },
      {
        key: "report",
        label: "Поскаржитись",
        visible: true,
      },
      {
        key: COMMENTS_ACTION_TYPES.REMOVE,
        label: "Видалити",
        danger: true,
        visible: permissions.can_delete,
      },
    ].filter((item) => item.visible);
  }, [permissions]);

  const handleMoreDotsAction = (type) => {
    if (type === COMMENTS_ACTION_TYPES.REMOVE) {
      setRemoveModalVisible(true);
    } else {
      onAction(type);
    }
  };

  return (
    <>
      {removeModalVisible && (
        <RemoveModal
          title={"Ви впевнені, що хочете видалити цей коментар?"}
          onSubmit={() => onAction("remove")}
          onCancel={() => setRemoveModalVisible(false)}
        />
      )}
      <Flex align={"center"} gap={8}>
        <Votes
          onVote={(voteType) => onAction("vote", { type: voteType })}
          activeVote={activeVote}
          votesCount={votesCount}
        ></Votes>
        <Flex gap={8} align="center">
          {toolbarActions.map((action) => (
            <Flex
              className={styles.actionButton}
              key={action.type}
              onClick={() => onAction(action.type)}
            >
              <div className={styles.actionIconText}>{action.text}</div>
            </Flex>
          ))}
        </Flex>
        <Dropdown
          menu={{
            items: dropdownItems,
            onClick: (e) => handleMoreDotsAction(e.key),
          }}
          placement="top"
          arrow
        >
          <Flex className={styles.actionButton}>
            <div className={styles.actionIcon}>
              <BsThreeDots size={18} />
            </div>
          </Flex>
        </Dropdown>
      </Flex>
    </>
  );
};

export { CommentsActions };
