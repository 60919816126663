import { Flex } from "antd";
import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { BackButton } from "../../components/back-button";
import { PostComments } from "../../components/comments/post-comments";
import { PostActionModals } from "../../components/post-action-modals";
import { PostCard } from "../../components/post/post-card";
import { Spinner } from "../../components/ui/spinner";
import {
  useGetPostByIdQuery,
  useRemovePostMutation,
  useUpdateVoteOnSinglePostMutation,
} from "../../features/api/posts-api";
import { fetchPostComment } from "../../features/post/post-actions";
import { removePostFromState } from "../../features/posts/posts-slice";
import styles from "./post-page.module.scss";

const PostPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [modal, setModal] = useState({
    type: null,
    data: null,
  });
  const { data: post, error: postError, isLoading } = useGetPostByIdQuery(id);
  const [removePost] = useRemovePostMutation();
  const [updateVote] = useUpdateVoteOnSinglePostMutation();

  const onAddNewThread = async ({ body, postId }) => {
    const res = await dispatch(fetchPostComment({ postId, body }));
    return res;
  };

  const onPostRemove = async () => {
    try {
      await removePost(id);
      dispatch(removePostFromState(id));
      navigate("/", {
        state: { scrollPos: location?.state?.scrollPos ?? undefined },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleActionWithId = ({ id, type, metadata }) => {
    if (type === "remove_request") {
      setModal({
        type: "remove_request",
        data: id,
      });
    } else if (type === "report") {
      setModal({
        type: "report",
        data: id,
      });
    } else if (type === "edit") {
      setModal({
        type: "edit",
        data: id,
      });
    } else {
      return onPostAction({ id, type, metadata });
    }
  };

  const onPostAction = ({ type, id: commentId, metadata }) => {
    switch (type) {
      case "comment": {
        break;
      }
      case "reply": {
        if (metadata) {
          return onAddNewThread({ body: metadata, postId: id });
        }
        break;
      }
      case "vote": {
        const { type } = metadata;
        updateVote({ postId: id, voteType: type });
        break;
      }
      case "remove": {
        onPostRemove();
      }
    }
  };

  const canViewPost = !isLoading && !postError;
  return (
    <div className={styles.pageWrap}>
      <PostActionModals
        type={modal?.type}
        onCancel={() => setModal({ type: null, data: null })}
        data={modal?.data}
        onSubmit={handleActionWithId}
      />
      <div className={styles.backButtonWrap}>
        <BackButton>
          <div>
            <BiArrowBack />
          </div>
        </BackButton>
      </div>
      <Flex className={styles.feedWrap} vertical gap={8}>
        {isLoading && (
          <div className={styles.loaderWrapper}>
            <Spinner />
          </div>
        )}
        {canViewPost && (
          <PostCard
            post={post}
            onAction={handleActionWithId}
            hasCommentForm={true}
          />
        )}
        <PostComments id={id} hidden={isLoading} />
      </Flex>
    </div>
  );
};

export { PostPage };
