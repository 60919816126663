import { format } from "date-fns";
import React from "react";
import { Link, generatePath } from "react-router-dom";

import ROUTES from "../../../../routing/routes";
import { Avatar } from "../../avatar/avatar";
import { NotificationCard } from "../../cards/notification-card/notification-card";

const getPath = (notificationData) => {
  const { class: sourceClass, id, post } = notificationData?.source || {};
  return sourceClass === "Post"
    ? generatePath(ROUTES.post, { id })
    : generatePath(ROUTES.post, { id: post?.id });
};

const NotificationMentionItem = ({ notificationData, onClick }) => {
  const { id, source, updated_at } = notificationData;
  const { profile } = source || {};

  return (
    <Link onClick={onClick} key={id} to={getPath(notificationData)}>
      <NotificationCard
        icon={<Avatar size={40} url={profile?.profile_image?.small?.url} />}
        subTitle={`Згадав вас ${format(updated_at, "p")}`}
        title={profile?.display_name}
      />
    </Link>
  );
};

export { NotificationMentionItem };
