import { SmsNotification } from "iconsax-react";
import React from "react";

import { getFollowersMessage } from "../../../../utils/helpers/string-transform";
import { ProfileInfoTooltip } from "../../../user-popover";
import { GenericNotificationGroupList } from "../generic-notification-group-list";
import { NotificationMentionItem } from "../notification-mention-item";

const MentionsList = ({ notifications, onClick }) => {
  return (
    <GenericNotificationGroupList
      list={notifications}
      title={"Нові згадки"}
      subTitle={getFollowersMessage(notifications?.length)}
      icon={<SmsNotification size="24" />}
      renderItem={(notification) => {
        return (
          <ProfileInfoTooltip
            key={notification.id}
            username={notification?.source?.profile?.username}
          >
            <NotificationMentionItem
              onClick={onClick}
              notificationData={notification}
            />
          </ProfileInfoTooltip>
        );
      }}
    />
  );
};

export { MentionsList };
