import React from "react";

import LoginForm from "../../forms/login";
import LoginButtons from "../login-buttons";

const LoginComponent = ({ error, onSubmit, isLoading, onChangeType }) => {
  return (
    <>
      <LoginForm
        error={error}
        onSubmit={onSubmit}
        isLoading={isLoading}
        onChangeType={onChangeType}
      />
      <LoginButtons />
    </>
  );
};

export default LoginComponent;
