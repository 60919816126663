import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";

import { selectUsersResult } from "../features/api/user-api";
import {
  fetchNextPosts,
  fetchPosts,
  fetchRemovePost,
  fetchUpdateVote,
} from "../features/posts/posts-actions";
import {
  isFetchingNextFeedPosts,
  selectFeedError,
  selectFeedIsLoading,
  selectFeedPostLastId,
  selectFeedPosts,
  selectHasMoreFeedPosts,
} from "../features/posts/posts-slice";
import ROUTES from "../routing/routes";

const useFeedPosts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const posts = useSelector(selectFeedPosts);
  const post_last_id = useSelector(selectFeedPostLastId);
  const hasMorePosts = useSelector(selectHasMoreFeedPosts);
  const isFetchingNextPage = useSelector(isFetchingNextFeedPosts);
  const error = useSelector(selectFeedError);
  const isLoading = useSelector(selectFeedIsLoading);

  const profile = useSelector(selectUsersResult);

  useEffect(() => {
    if (!posts?.length) {
      dispatch(fetchPosts({ from_id: null, source: "feedPosts" }));
    }
  }, [profile?.data]);

  const getNextPosts = () => {
    if (post_last_id) {
      dispatch(fetchNextPosts({ from_id: post_last_id, source: "feedPosts" }));
    }
  };

  const onComment = ({ id }) => {
    navigate(generatePath(ROUTES.post, { id }), {
      state: { scrollPos: window.scrollY },
    });
  };

  const onPostAction = useCallback(
    ({ id, type, metadata }) => {
      switch (type) {
        case "comment": {
          onComment({ id });
          break;
        }
        case "vote": {
          const { type: voteType } = metadata;
          dispatch(fetchUpdateVote({ postId: id, voteType }));
          break;
        }
        case "remove": {
          dispatch(fetchRemovePost({ postId: id }));
          break;
        }
        default:
          break;
      }
    },
    [dispatch],
  );

  return {
    posts,
    isLoading,
    error,
    onPostAction,
    getPosts: getNextPosts,
    hasMorePosts: hasMorePosts,
    isFetchingNextPage,
  };
};

export default useFeedPosts;
