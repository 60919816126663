import React from "react";

import { FollowersList } from "../followers-list/followers-list";
import { MentionsList } from "../mentions-list/mentions-list";
import { NotificationGroup } from "../notification-group";

const NotificationsList = ({ notifications, handleCloseDrawer }) => {
  return (
    notifications.length > 0 &&
    notifications?.map((notification) => (
      <NotificationGroup title={notification.group} key={notification.group}>
        {notification.events?.follow?.length > 0 && (
          <FollowersList
            onClick={handleCloseDrawer}
            notifications={notification.events?.follow}
          />
        )}
        {notification.events?.mention?.length > 0 && (
          <MentionsList
            onClick={handleCloseDrawer}
            notifications={notification.events?.mention}
          />
        )}
      </NotificationGroup>
    ))
  );
};

export { NotificationsList };
