import React from "react";

import { ReactComponent as BrokenHeart } from "../../assets/icons/reactions/broken-heart.svg";
import { ReactComponent as Downvote } from "../../assets/icons/reactions/downvote.svg";
import { ReactComponent as Heart } from "../../assets/icons/reactions/heart.svg";
import { ReactComponent as Shit } from "../../assets/icons/reactions/shit.svg";
import { ReactComponent as Upvote } from "../../assets/icons/reactions/upvote.svg";

import { ReactComponent as BrokenHeartActive } from "../../assets/icons/reactions/broken-heart-active.svg";
import { ReactComponent as DownvoteActive } from "../../assets/icons/reactions/downvote-active.svg";
import { ReactComponent as HeartActive } from "../../assets/icons/reactions/heart-active.svg";
import { ReactComponent as ShitActive } from "../../assets/icons/reactions/shit-active.svg";
import { ReactComponent as UpvoteActive } from "../../assets/icons/reactions/upvote-active.svg";
import facebook from "../../assets/icons/social/facebook.svg";
import google from "../../assets/icons/social/google.svg";
import x from "../../assets/icons/social/x.svg";
import { ReactComponent as Visa } from "../../assets/icons/metadata/visa.svg";
import { ReactComponent as Mastercard } from "../../assets/icons/metadata/mastercard.svg";

const selfProfileLabels = {
  edit_avatar: "Редагувати аватар",
  edit_cover: "Редагувати фон",
  edit_bio: "Редагувати біо",
};

export const selfProfileActionsValues = {
  edit_avatar: "edit_avatar",
  edit_cover: "edit_cover",
  edit_bio: "edit_bio",
};

export const selfProfileActions = [
  {
    label: selfProfileLabels.edit_avatar,
    key: selfProfileActionsValues.edit_avatar,
  },
  {
    label: selfProfileLabels.edit_cover,
    key: selfProfileActionsValues.edit_cover,
  },
  {
    label: selfProfileLabels.edit_bio,
    key: selfProfileActionsValues.edit_bio,
  },
];

export const postReportTypes = [
  {
    label: 'Спам',
    value: '1',
  },
  {
    label: 'Пост говно',
    value: '2',
  },
  {
    label: 'Пропаганда чего то нехорошего',
    value: '3',
  },
  {
    label: 'Недостатньо потужно',
    value: '4',
  },
  {
    label: 'Інше',
    value: '5',
  },
]

export const socialButtons = [
  {
    key: "1",
    name: "Google",
    icon: google,
  },
  {
    key: "2",
    name: "X",
    icon: x,
  },
  {
    key: "3",
    name: "Facebook",
    icon: facebook,
  },
];

export const votesActions = [
  {
    type: "upvote",
    icon: <Upvote />,
    iconActive: <UpvoteActive/>,
    btnColor: "#7E7AFF",
    bgColor: "#D3D1FF",
    lblColor: "#7E7AFF",
  },
  {
    type: "downvote",
    icon: <Downvote />,
    iconActive: <DownvoteActive/>,
    btnColor: "#F57B0B",
    bgColor: "#FFE3C5",
    lblColor: "#F57B0B",
  },
  {
    type: "heart",
    icon: <Heart />,
    iconActive: <HeartActive/>,
    btnColor: "#F92020",
    bgColor: "#FFD2D2",
    lblColor: "#F92020",
  },
  {
    type: "broken",
    icon: <BrokenHeart />,
    iconActive: <BrokenHeartActive/>,
    btnColor: "#1FBCFF",
    bgColor: "#CAECF7",
    lblColor: "#1FBCFF",
  },
  {
    type: "shit",
    icon: <Shit />,
    iconActive: <ShitActive/>,
    btnColor: "#C16B15",
    bgColor: "#EFCCAA",
    lblColor: "#C16B15",
  },
];

export const cardProviders = {
  visa: <Visa/>,
  mastercard: <Mastercard/>,
}

export const loginSlogans = [
  {
    title: "Зрозумілі умови",
    text: "Ми всі чули про соцмережі, де можна втратити обліковий запис за безневинні фото або навіть простий текст. Сподіваємось, це міська легенда — у нас так не буде.",
  },
  {
    title: "Вільні висловлювання",
    text: "Новини, політика, історії легендарних кораблів, фото улюбленої кішки, кінокритика, збори для ЗСУ — тут знайдеться місце будь-яким інтересам. Ну, майже будь-яким.",
  },
  {
    title: "Це тільки початок",
    text: "Чули про корабель Тесея? Ми лише вирушаємо у плавання і ще не знаємо, як змінимося в дорозі, але попереду — великі плани. Почнемо разом, і ви побачите все першими!",
  },
]