import { Button } from "antd";
import React from "react";

import { ScrollableNotificationsList } from "../../ui/notifications/scrollable-notifications-list";
import styles from "../notifications.module.scss";

const UserNotifications = ({
  onClose,
  fetchNext,
  hasMore,
  notifications,
  isNotificationsLoading,
}) => {
  return (
    <>
      <ScrollableNotificationsList
        fetchNext={fetchNext}
        notifications={notifications}
        hasMore={hasMore}
        onClose={onClose}
      />
      {hasMore && (
        <div className={styles.loadMoreButton}>
          <Button onClick={fetchNext} loading={isNotificationsLoading}>
            Load more
          </Button>
        </div>
      )}
    </>
  );
};

export { UserNotifications };
