export const getStartPageTitle = (type) => {
  switch (type) {
    case "start":
      return "Створити акаунт";
    case "sign-up":
      return "Створити акаунт";
    case "remind":
      return "Забули пароль?";
    case "login":
    default:
      return "Вхід до Vmist!";
  }
};

export function getAllErrors(json) {
  let messages = [];

  function traverse(obj) {
    for (let key in obj) {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item) => {
          if (typeof item === "string") {
            messages.push(item);
          } else if (typeof item === "object") {
            traverse(item);
          }
        });
      } else if (typeof obj[key] === "object") {
        traverse(obj[key]);
      }
    }
  }

  traverse(json);
  return messages;
}

export function checkPasswordRequirements(password) {
  const hasDigit = /\d/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasSymbol = /[^a-zA-Z0-9]/.test(password);
  const hasMinimumLength = password.length >= 8;

  return {
    hasDigit: hasDigit,
    hasLowerCase: hasLowerCase,
    hasUpperCase: hasUpperCase,
    hasSymbol: hasSymbol,
    hasMinimumLength: hasMinimumLength,
  };
}

export function getFollowersMessage(followersCount) {
  if (followersCount === 1) {
    return `${followersCount} людина підписалася на ваш профіль`;
  }

  const lastDigit = followersCount % 10;
  const lastTwoDigits = followersCount % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return `${followersCount} людей підписалося на ваш профіль`;
  }

  if (lastDigit === 1) {
    return `${followersCount} людина підписалася на ваш профіль`;
  }

  if (lastDigit >= 2 && lastDigit <= 4) {
    return `${followersCount} людини підписалися на ваш профіль`;
  }

  return `${followersCount} людей підписалося на ваш профіль`;
}

export function getMentionsMessage(mentionsCount) {
  const lastDigit = mentionsCount % 10;
  const lastTwoDigits = mentionsCount % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return `Вас згадали ${mentionsCount} разів`;
  }

  if (lastDigit === 1) {
    return `Вас згадали ${mentionsCount} раз`;
  }

  return `Вас згадали ${mentionsCount} разів`;
}
