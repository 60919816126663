import RegisterButtons from "../register-buttons";
import {Button, Flex} from "antd";
import styles from "./register.module.scss";

const StartComponent = ({onChangeType}) => {
  return (
    <Flex vertical gap={16}>
      <Button
        type="primary"
        size="xl"
        className={styles.registerButton}
        onClick={() => onChangeType("sign-up")}
      >
        Зареєструватись з Email
      </Button>
      <RegisterButtons />
    </Flex>
  );
};

export { StartComponent };
