import { Drawer } from "antd";
import React from "react";

import useNotifications from "../../../hooks/use-notifications";
import { NOTIFICATIONS_ASIDE_WIDTH } from "../../../utils/constants/integers";
import { Message } from "../message";
import styles from "../notifications.module.scss";
import { UserNotifications } from "../user-notifications";

const NotificationsDrawer = ({ onClose, open }) => {
  const {
    data: notifications,
    fetchNext,
    hasMore,
    isLoading: isNotificationsLoading,
  } = useNotifications();

  return (
    <Drawer
      title="Сповіщення"
      onClose={onClose}
      open={open}
      width={NOTIFICATIONS_ASIDE_WIDTH}
      extra={true}
    >
      <div className={styles.notifyContainer}>
        <div className={styles.notifyColumn}>
          <Message
            title={"Нові функції платформи"}
            text={"Ознайомтеся з нашими новими функціями та можливостями."}
            link={"#"}
            linkText={"Дізнайтеся більше зараз!"}
          />
        </div>
        <div className={styles.notifyColumn}>
          <UserNotifications
            onClose={onClose}
            notifications={notifications}
            fetchNext={fetchNext}
            hasMore={hasMore}
            isNotificationsLoading={isNotificationsLoading}
          />
        </div>
      </div>
    </Drawer>
  );
};

export { NotificationsDrawer };
