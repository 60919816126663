import styles from "./sign-up-type-links.module.scss";

const SignUpLinks = ({ type, onChangeType }) => {
  switch (type) {
    case "start":
      return (
        <div className={styles.bottomLinks}>
          Вже є акаунт?&nbsp;
          <span className={styles.link} onClick={() => onChangeType("login")}>Увійти</span>
        </div>
      );
    case "sign-up":
      return (
        <div className={styles.bottomLinks}>
          Вже є акаунт?&nbsp;
          <span className={styles.link} onClick={() => onChangeType("login")}>Увійти</span>
        </div>
      );
    case "remind":
      return (
        <div className={styles.bottomLinks}>
          <span className={styles.link} onClick={() => onChangeType("login")}>Я згадав пароль</span>
        </div>
      );
    case "login":
    default:
      return (
        <div className={styles.bottomLinks}>
          <div>
            Немає акаунта?&nbsp;
            <span
              className={styles.link}
              onClick={() => onChangeType("start")}
            >
              Зарєєструйтесь
            </span>
          </div>
        </div>
      );
  }
};

export { SignUpLinks };
