import { ReactComponent as LockIcon } from "@/assets/icons/post/lock.svg";
import React from "react";

import styles from "./ask-to-unblock-container.module.scss";

const AskToUnblockContainer = () => {
  return (
    <div className={styles.wrapper}>
      <LockIcon />
      <h2 className={styles.title}>
        Вас заблокували. Щоб переглянути стрічку та продовжити взаємодію із
        користувачем, ви можете надіслати запит на розблокування за допомогою
        меню (...)
      </h2>
    </div>
  );
};

export { AskToUnblockContainer };
