import axiosInstance from "../../services/axios";

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, body, headers }) => {
    try {
      const result = await axiosInstance({
        url: baseUrl + url,
        method,
        data: body,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError.response
        ? {
            status: axiosError.response.status,
            data: axiosError.response.data,
          }
        : { status: 500, data: axiosError.message };
      return { error: err };
    }
  };

export default axiosBaseQuery;
