import { fetchCreatePost } from "@/features/posts/posts-actions";
import routes from "@/routing/routes";
import { Modal } from "antd";
import { AddSquare } from "iconsax-react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMatch } from "react-router-dom";

import * as $vars from "../../../utils/constants/variables";
import PostForm from "../../forms/post-form/post-form";
import styles from "./new-post-modal.module.scss";

const NewPostModal = () => {
  const dispatch = useDispatch();
  const isProfile = useMatch(routes.profile);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onCreatePost = async ({ data }) => {
    try {
      const source = isProfile ? "profilePosts" : "feedPosts";
      return dispatch(fetchCreatePost({ body: data, source })).then((res) => {
        if (!res.payload?.errors) setIsModalOpen(false);
        return res;
      });
    } catch (e) {
      console.log("onCreatePost error", e);
    }
  };

  return (
    <>
      <div className={styles.newPostButton} onClick={showModal}>
        <AddSquare size="24" color={$vars.colors.grey} />
      </div>
      <Modal
        open={isModalOpen}
        width={$vars.sizing.newPostModal}
        title={false}
        header={null}
        footer={null}
        centered
        onCancel={handleCancel}
        closeIcon={false}
        wrapClassName={"new-post-modal"}
      >
        <PostForm onCancel={handleCancel} onSubmit={onCreatePost} />
      </Modal>
    </>
  );
};

export { NewPostModal };
