import { Collapse } from "antd";
import React from "react";

import { NotificationCard } from "../../cards/notification-card/notification-card";

const GenericNotificationGroupList = ({
  list,
  title,
  subTitle,
  icon,
  renderItem,
}) => {
  return (
    <div>
      <Collapse headerPadding={0}>
        <Collapse.Panel
          header={
            <NotificationCard icon={icon} title={title} subTitle={subTitle} />
          }
          showArrow={false}
          key={1}
        >
          {list?.length > 0 && list.map((item) => renderItem(item))}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export { GenericNotificationGroupList };
