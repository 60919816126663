const ROUTES = {
  profile: "/profile",
  otherProfile: "/:slag",
  settings: {
    main: "/settings",
    privacy: "/settings/privacy",
    profile: "/settings/profile",
    premium: "/settings/premium",
    site: "/settings/edit",
  },
  home: "/",
  post: "/posts/:id",
  error: "/error",
};

export default ROUTES;
