import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getComments,
  getThreads,
  postComment,
  postVote,
  removeComment,
} from "../../services/comment-service";

export const fetchThreadsForPost = createAsyncThunk(
  "post/fetchThreadsForPost",
  async ({ id }, { rejectWithValue }) => {
    try {
      const posts = await getThreads(id);
      return posts?.data;
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  },
);

export const fetchNextThreads = createAsyncThunk(
  "post/fetchNextThreads",
  async ({ id, from_id }) => {
    const posts = await getThreads(id, from_id);
    return posts?.data;
  },
);

export const fetchPostComment = createAsyncThunk(
  "post/postComment",
  async ({ postId, body, comment_id }, thunkAPI) => {
    try {
      const posts = await postComment(postId, body, comment_id);
      return posts?.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const fetchComments = createAsyncThunk(
  "post/fetchComments",
  async ({ from_id, post_id }) => {
    const posts = await getComments({ from_id, post_id });
    return posts?.data;
  },
);

export const fetchCommentVote = createAsyncThunk(
  "post/fetchCommentVote",
  async ({ comment_id, vote_type, post_id }) => {
    const posts = await postVote({ comment_id, vote_type, post_id });
    return posts?.data;
  },
);

export const fetchRemoveComment = createAsyncThunk(
  "post/fetchDeleteComment",
  async ({ comment_id, post_id, thread_id }) => {
    const posts = await removeComment({ comment_id, post_id, thread_id });
    return posts?.data;
  },
);
