import { Button, Flex, Popover, Tooltip } from "antd";
import { Lock1, Unlock } from "iconsax-react";
import React from "react";

import cn from "../../../utils/helpers/className";
import FileUploadTooltip from "../../forms/post-form/components/file-upload-tooltip/file-upload-tooltip";
import SvgIcon from "../svg-icon/svg-icon";
import styles from "./post-form-footer.module.scss";

const PostFormFooter = ({
  onUploadClick,
  isLocked,
  onLockToggle,
  onCancel,
  isSubmitDisabled,
}) => (
  <div className={styles.wrapper}>
    <Flex className={styles.settings} align="center">
      <Popover
        placement="bottomLeft"
        content={<FileUploadTooltip className={styles.tooltipMessage} />}
        arrow={true}
      >
        <Button
          onClick={onUploadClick}
          type="default"
          size="large"
          className={styles.container}
          style={{
            display: "flex",
            alignItems: "center",
            padding: "9px",
          }}
        >
          <SvgIcon
            className={cn(styles.icon, styles.paperClip)}
            name={"paper-clip"}
            width={20}
            height={20}
          />
        </Button>
      </Popover>
      <div className={styles.container}>
        <Button
          onClick={onLockToggle}
          type={isLocked ? "primary" : "default"}
          size="large"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "7px",
          }}
        >
          {isLocked ? (
            <Lock1 size="24" color={"#fff"} />
          ) : (
            <Unlock size="24" color={"#000"} />
          )}
        </Button>
      </div>
    </Flex>
    <div className={styles.buttons}>
      <Button
        className={styles.item}
        type="default"
        size="large"
        onClick={onCancel}
      >
        Закрити
      </Button>
      <Tooltip title="Ctrl + Enter">
        <Button
          className={styles.item}
          disabled={isSubmitDisabled}
          type="primary"
          size="large"
          htmlType={"submit"}
        >
          Опублікувати
        </Button>
      </Tooltip>
    </div>
  </div>
);

export { PostFormFooter };
