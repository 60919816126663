import ROUTES from "../../routing/routes";
import { metadataTypes } from "../constants/integers";
import { load } from "./local-storage";

export const getScrollKey = (location) => {
  const paths = [ROUTES.home, ROUTES.profile];
  return paths.includes(location.pathname) ? location.pathname : location.key;
};

export function hasJWT() {
  return load("accessToken");
}

export const getLinksMetadata = (links) => {
  if (!links) return [];
  return links?.filter(
    (link) => link?.metadata_type === metadataTypes.url_preview,
  );
};

export const checkAndConvertToEmbedYoutube = (url) => {
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const match = url.match(youtubeRegex);

  if (match && match[1]) {
    return `https://www.youtube.com/embed/${match[1]}`;
  }

  return false;
};
