import styles from './message.module.scss';

const Message = ({ title, text, link, linkText }) => {
  return (
    <div className={styles.Message}>
      <div className={styles.MessageContainer}>
        <div className={styles.MessageTitle}>{title}</div>
        <div className={styles.MessageText}>{text} <a href={link}>{linkText}</a></div>
      </div>
    </div>
  );
};

export { Message };
