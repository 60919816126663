import { useEffect } from "react";

const usePasteHandler = (onPaste, formRef) => {
  useEffect(() => {
    window.addEventListener("paste", onPaste);
    return () => window.removeEventListener("paste", onPaste);
  }, [onPaste, formRef]);
};

export default usePasteHandler;
