import React from "react";

import styles from "./notification-group.module.scss";

const NotificationGroup = ({ title, children }) => {
  return (
    <div className={styles.group}>
      <div>
        <div className={styles.groupTitle}>{title}</div>
        <div className={styles.groupList}>{children}</div>
      </div>
    </div>
  );
};

export { NotificationGroup };
