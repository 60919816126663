import React from "react";

import styles from "./icon-button.module.scss";
import {Button} from "antd";

const IconButton = ({ icon: Icon, title, disabled }) => {
  const handleClick = (event) => {
    event.preventDefault();
  };

  return (
    <Button className={styles.button} onClick={handleClick} disabled={disabled}>
      <Icon className={styles.buttonIcon} />
      <span className={styles.buttonText}>{title}</span>
    </Button>
  );
};

export default IconButton;
