import { useNavigate } from "react-router-dom";

import { useSignUpMutation } from "../../../../features/api/user-api";
import ROUTES from "../../../../routing/routes";

const useSignUp = () => {
  const [signUp, { isLoading: isSignUpLoading, error: signUpError }] =
    useSignUpMutation();
  const navigate = useNavigate();

  const handleSignUp = (data) => {
    return signUp(data)
      .unwrap()
      .then(() => {
        navigate(ROUTES.home);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };

  return {
    handleSignUp,
    signUpError,
    isSignUpLoading,
  };
};

export default useSignUp;
