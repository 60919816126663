import { createConsumer } from "@anycable/web";
import React, { createContext } from "react";

import { load } from "../helpers/local-storage";

export const CableContext = createContext(null);

const CableProvider = ({ children }) => {
  const token = load("accessToken");
  let cable = createConsumer(`${process.env.REACT_APP_WS_URL}?token=${token}`);

  if (!token) return children;
  return (
    <CableContext.Provider value={cable}>{children}</CableContext.Provider>
  );
};

export default CableProvider;
