import React from "react";

import { EditPostModal } from "../post/edit-post-modal";
import { RemoveModal } from "../ui/modals/remove-modal";
import { ReportModal } from "../ui/modals/report-modal";

const PostActionModals = ({ type, data, onCancel, onSubmit }) => {
  if (!type) return null;
  switch (type) {
    case "remove_request":
      return (
        <RemoveModal
          title={"Видалити"}
          text={"Ця дія невідворотня. Ви дійсно бажаєте видалити цей пост?"}
          onSubmit={() => {
            onSubmit({ type: "remove", id: data });
            onCancel();
          }}
          onCancel={onCancel}
        />
      );
    case "report":
      return (
        <ReportModal postId={data} title={"Поскаржитись"} onCancel={onCancel} />
      );
    case "edit":
      return <EditPostModal postId={data} onCancel={onCancel} />;
    default:
      return null;
  }
};

export { PostActionModals };
