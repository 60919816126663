import React from "react";

import styles from "./notification-card.module.scss";

const NotificationCard = ({ title, subTitle, icon }) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.body}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{subTitle}</div>
      </div>
    </div>
  );
};

export { NotificationCard };
