import { useEffect, useState } from "react";

import { useLazyGetNotificationsQuery } from "../features/api/notifications-api";

const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [fromId, setFromId] = useState(null);
  const [trigger, { data, isFetching, isError }] =
    useLazyGetNotificationsQuery();

  const getNotifications = async (fromId) => {
    if (!fromId && !notifications.length) {
      await trigger(undefined).then((response) => {
        if (response.data) {
          setNotifications(response.data.event_groups);
          setFromId(response.data.event_last_id);
        }
      });
    }
  };

  useEffect(() => {
    getNotifications();
  }, [fromId]);

  const fetchNext = async () => {
    if (!fromId) return;
    await trigger(fromId).then((response) => {
      if (response.data) {
        setNotifications([...notifications, ...response.data.event_groups]);
        setFromId(response.data.event_last_id);
      }
    });
  };

  return {
    data: notifications,
    isLoading: isFetching,
    isError,
    refetch: trigger,
    hasMore: !!fromId,
    fetchNext,
  };
};

export default useNotifications;
