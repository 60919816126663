import { Button, Flex, Popover, Tooltip } from "antd";
import React from "react";

import cn from "../../../utils/helpers/className";
import FileUploadTooltip from "../../forms/post-form/components/file-upload-tooltip/file-upload-tooltip";
import SvgIcon from "../svg-icon/svg-icon";
import styles from "./comment-form-footer.module.scss";

const CommentFormFooter = ({ onUploadClick, onCancel, isSubmitDisabled }) => (
  <div className={styles.wrapper}>
    <Flex className={styles.settings} align="center">
      <Popover
        placement="bottomLeft"
        content={<FileUploadTooltip className={styles.tooltipMessage} />}
        arrow={true}
      >
        <Button
          onClick={onUploadClick}
          type="default"
          size="large"
          className={styles.container}
          style={{
            display: "flex",
            alignItems: "center",
            padding: "9px",
          }}
        >
          <SvgIcon
            className={cn(styles.icon, styles.paperClip)}
            name={"paper-clip"}
            width={20}
            height={20}
          />
        </Button>
      </Popover>
    </Flex>
    <div className={styles.buttons}>
      <Button
        className={styles.item}
        type="default"
        size="large"
        onClick={onCancel}
      >
        Очистити
      </Button>
      <Tooltip title="Ctrl + Enter">
        <Button
          className={styles.item}
          disabled={isSubmitDisabled}
          type="primary"
          size="large"
          htmlType={"submit"}
        >
          Зберегти
        </Button>
      </Tooltip>
    </div>
  </div>
);

export { CommentFormFooter };
