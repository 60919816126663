import {
  useAskForUnblockMutation,
  useCreateRelationMutation,
  useRemoveRelationMutation,
} from "@/features/api/user-api";
import React from "react";

import { FollowButton } from "../../ui/buttons/follow-button/follow-button";

const OtherProfileActionsButton = ({ relation, username, permissions }) => {
  const [createRelation] = useCreateRelationMutation();
  const [removeRelations] = useRemoveRelationMutation();
  const [askForUnblock] = useAskForUnblockMutation();

  const onClick = ({ key }) => {
    switch (key) {
      case "follow":
        createRelation({ username: username, relation_type: key });
        break;
      case "unfollow":
        removeRelations({ username: username, relation_type: "follow" });
        break;
      case "block":
        createRelation({ username: username, relation_type: key });
        break;
      case "unblock":
        removeRelations({ username: username, relation_type: "block" });
        break;
      case "askForUnblock":
        askForUnblock({ username: username });
        break;
      default:
        break;
    }
  };

  return (
    <FollowButton
      relations={relation}
      onClick={onClick}
      permissions={permissions}
    />
  );
};

export { OtherProfileActionsButton };
