import { useState } from "react";

import { HeaderNotificationButton } from "../ui/notifications/header-notification-button";
import { NotificationsDrawer } from "./notifications-drawer";

const Notifications = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  return (
    <>
      <HeaderNotificationButton onClick={showDrawer} />
      <NotificationsDrawer open={open} onClose={handleCloseDrawer} />
    </>
  );
};

export { Notifications };
