import { Flex, Tag } from "antd";
import React, { useCallback } from "react";
import InfiniteScroll from "react-infinite-scroller";

import { usePostComments } from "../../../pages/post-page/hooks/usePostComments";
import styles from "../../../pages/post-page/post-page.module.scss";
import { Spinner } from "../../ui/spinner";
import { Comments } from "../comments";

const PostComments = ({ id, hidden }) => {
  const {
    fetchCommentVote,
    getComments,
    onAddCommentToThread,
    handleLoadNextThreadComments,
    isLoading: isLoadingThreads,
    error: threadsError,
    isLoadingNext: isLoadingNextThreads,
    data: threads,
    commentsLeft: numberOfNotLoadedThreads,
    handleCommentRemove,
  } = usePostComments({ id });

  const onCommentAction = useCallback(
    ({ type, id: commentId, parentId, metadata }) => {
      switch (type) {
        case "vote": {
          const { type } = metadata;
          fetchCommentVote({
            comment_id: commentId,
            vote_type: type,
            post_id: id,
            parentId,
          });
          break;
        }
        case "comment": {
          if (!parentId) {
            console.log("comment");
          }
          break;
        }
        case "reply": {
          if (metadata) {
            return onAddCommentToThread({
              postId: id,
              body: metadata,
              commentId: commentId || null,
            });
          }
          break;
        }
        case "preload_comments": {
          handleLoadNextThreadComments({
            fromId: metadata.comment_last_id,
            postId: id,
            parentId: commentId,
          });
          break;
        }
        case "remove": {
          handleCommentRemove({
            comment_id: commentId,
            thread_id: parentId,
            post_id: id,
          });
          break;
        }
      }
    },
    [id],
  );

  const commentsLoadingErrorMessageVisible =
    threadsError && !isLoadingThreads && !isLoadingNextThreads;
  const updateCommentsButtonVisible =
    !isLoadingNextThreads && !hidden && !isLoadingThreads && !threadsError;

  return (
    <div>
      {isLoadingThreads && (
        <div className={styles.infinitiLoaderWrapper}>
          <Spinner />
        </div>
      )}
      <Flex vertical gap={12}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={getComments}
          hasMore={Boolean(
            numberOfNotLoadedThreads > 0 && !isLoadingNextThreads,
          )}
          threshold={500}
        >
          <Comments comments={threads} onAction={onCommentAction} />
        </InfiniteScroll>
      </Flex>
      {commentsLoadingErrorMessageVisible && (
        <div className={styles.wrapperCentered}>{threadsError}</div>
      )}
      {isLoadingNextThreads && (
        <div className={styles.infinitiLoaderWrapper}>
          <Spinner />
        </div>
      )}
      {updateCommentsButtonVisible && (
        <div className={styles.wrapperCentered}>
          <Tag
            color="default"
            onClick={getComments}
            style={{ cursor: "pointer" }}
          >
            {!threads?.length
              ? "Оновити коментарі"
              : "Завантажити ще коментарі..."}
          </Tag>
        </div>
      )}
    </div>
  );
};

export { PostComments };
