import * as $vars from "@/utils/constants/variables";
import { Badge } from "antd";
import { Notification } from "iconsax-react";
import React from "react";

import styles from "../../../notifications/notifications.module.scss";

const HeaderNotificationButton = ({ onClick }) => {
  return (
    <Badge status="success" dot={true} onClick={onClick} offset={[-16, 14]}>
      <div className={styles.notifyButton}>
        <Notification size="24" color={$vars.colors.grey} />
      </div>
    </Badge>
  );
};

export { HeaderNotificationButton };
