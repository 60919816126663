import queryString from "query-string";

import api from "./axios";

export const getThreads = async (id, from_id) => {
  const params = queryString.stringify({ from_id });
  return await api.get(`/posts/${id}/comments?${params}`);
};

export const postComment = async (postId, body, parentOrCommentId) => {
  const isReplyTo = parentOrCommentId ? `/${parentOrCommentId}` : "";
  return await api.post(`/posts/${postId}/comments${isReplyTo}`, body);
};

export const getComments = async ({ from_id, post_id }) => {
  const params = queryString.stringify({ from_id });
  return await api.get(`/posts/${post_id}/comments?${params}`);
};

export const postVote = async ({ comment_id, vote_type, post_id }) => {
  return await api.post(`/posts/${post_id}/comments/${comment_id}/votes`, {
    vote_type,
  });
};

export const removeComment = async ({ comment_id, post_id, thread_id }) => {
  return await api.delete(
    `/posts/${post_id}/comments/${comment_id || thread_id}`,
  );
};
