import { ReactComponent as FacebookIcon } from "@/assets/icons/social/facebook.svg";
import { ReactComponent as GoogleIcon } from "@/assets/icons/social/google.svg";
import { ReactComponent as XIcon } from "@/assets/icons/social/x.svg";

import LoginButtons from "./login-buttons";

export default LoginButtons;

export const SocialButtonsList = [
  {
    id: 0,
    icon: GoogleIcon,
    title: "Увійти з Google",
  },
  {
    id: 1,
    icon: FacebookIcon,
    title: "Увійти з Facebook",
    disabled: true,
  },
  {
    id: 2,
    icon: XIcon,
    title: "Увійти з X",
    disabled: true,
  },
];
