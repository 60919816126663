import { Navigate, Outlet } from "react-router-dom";

import { hasJWT } from "../utils/helpers/utils";

const AssignedUserRoute = ({ redirectPath = "/" }) => {
  if (!hasJWT()) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default AssignedUserRoute;
