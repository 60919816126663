import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import { PostActionModals } from "../../post-action-modals";
import { PostCard } from "../post-card";
import styles from "./post-list.module.scss";

const PostList = ({ posts = [], onAction, getPosts, hasMorePosts }) => {
  const [modal, setModal] = useState({
    type: null,
    data: null,
  });

  const handleActionWithId = ({ id, type, metadata }) => {
    if (type === "remove_request") {
      setModal({
        type: "remove_request",
        data: id,
      });
    } else if (type === "report") {
      setModal({
        type: "report",
        data: id,
      });
    } else if (type === "edit") {
      setModal({
        type: "edit",
        data: id,
      });
    } else {
      onAction({ id, type, metadata });
    }
  };

  return (
    <>
      <PostActionModals
        type={modal?.type}
        onCancel={() => setModal({ type: null, data: null })}
        data={modal?.data}
        onSubmit={handleActionWithId}
      />
      <InfiniteScroll
        initialLoad={false}
        loadMore={getPosts}
        hasMore={hasMorePosts}
        useWindow={true}
        threshold={200}
      >
        <div className={styles.list}>
          {posts?.length > 0 &&
            posts.map((post) => (
              <PostCard
                key={post.id}
                post={post}
                onAction={handleActionWithId}
                hasShadow={true}
              />
            ))}
        </div>
      </InfiniteScroll>
    </>
  );
};

export { PostList };
