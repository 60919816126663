import axios from "axios";

import { default as AxiosWithToken } from "./axios";

export const uploadToPresignedUrl = async (
  presignedUrl,
  selectedFile,
  fields,
  onUploadProgressChange,
) => {
  const formData = new FormData();

  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key]);
  });
  formData.append("file", selectedFile);

  let finalProgressCalled = false;

  try {
    await axios
      .post(presignedUrl, formData, {
        onUploadProgress: (progressEvent) => {
          if (!onUploadProgressChange) {
            return;
          }
          if (!finalProgressCalled) {
            const progress = Math.round(progressEvent.progress * 100);
            onUploadProgressChange(progress);
          }
        },
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          finalProgressCalled = true;
        }
      });

    onUploadProgressChange(100);
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};

export const getPreSignedUrl = async (filename) => {
  const response = await AxiosWithToken.get(`/s3/presign?filename=${filename}`);
  return response.data;
};

// export const saveAttachment = async ({ resource, attachment_type, key }) => {
//   try {
//     const response = await axios.post("/attachments", {
//       resource,
//       attachment_type,
//       key,
//     });
//     return response.data;
//   } catch (e) {
//     console.log(e);
//   }
// };
