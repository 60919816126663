import ROUTES from "@/routing/routes";
import { formatDateTemplate } from "@/utils/helpers/date";
import { Flex, Tooltip, Typography } from "antd";
import { format } from "date-fns";
import { ArrowRight } from "iconsax-react";
import React, { memo, useState } from "react";
import { Link, generatePath } from "react-router-dom";

import { FormattedMetadata } from "../../formatted-metadata";
import { CommentForm } from "../../forms/comment-form";
import PostImages from "../../post/post-images/post-images";
import { Avatar } from "../../ui/avatar/avatar";
import { Spinner } from "../../ui/spinner";
import { ProfileInfoTooltip } from "../../user-popover";
import CommentsActions from "../comments-actions";
import styles from "./thread-comment.module.scss";

const ThreadComment = ({
  comment: {
    id,
    external_images,
    body,
    metadata,
    votes_up,
    votes_down,
    reposts_count,
    comments_count,
    profile: { display_name, username, profile_image },
    published_at_str,
    isLoading,
    comment_last_id,
    comments_left,
    published_at,
    error,
    vote,
    votes_sum,
    reply_to,
    permissions,
  },
  onAction,
  parentId,
  isLast,
}) => {
  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const handleAction = (type, metadata) => {
    if (type === "comment") {
      setIsReplyOpen(!isReplyOpen);
    }
    return onAction({
      type,
      id,
      parentId,
      metadata,
    })?.then((res) => {
      if (type === "reply" && res?.payload?.id) {
        setIsReplyOpen(false);
        return res;
      } else {
        return res;
      }
    });
  };
  return (
    <div className={styles.threadCommentWrap}>
      {!isLast && <div className={styles.secondLevelLine} />}
      <Flex className={styles.threadCommentBox} gap={8}>
        <Link to={generatePath(ROUTES.otherProfile, { slag: username })}>
          <ProfileInfoTooltip username={username}>
            <Avatar size={40} url={profile_image?.small?.url} />
          </ProfileInfoTooltip>
        </Link>
        <Flex vertical gap={4} flex={1}>
          <Flex className={styles.commentHeader} align={"center"}>
            <Link
              className={styles.commentAuthor}
              to={generatePath(ROUTES.otherProfile, { slag: username })}
            >
              <ProfileInfoTooltip username={username}>
                {display_name}
              </ProfileInfoTooltip>
            </Link>
            <Tooltip
              title={
                published_at?.length && format(published_at, formatDateTemplate)
              }
            >
              <Link
                className={styles.commentDateTime}
                to={generatePath(ROUTES.otherProfile, { slag: username })}
              >
                {published_at_str}
              </Link>
            </Tooltip>
            {reply_to?.profile?.display_name && (
              <>
                <ArrowRight
                  size="13"
                  color="#ccc"
                  style={{ margin: "0 8px" }}
                />
                <Link className={styles.commentReplyTo} to={"#"}>
                  <ProfileInfoTooltip username={reply_to?.profile?.username}>
                    {reply_to?.profile?.display_name}
                  </ProfileInfoTooltip>
                </Link>
              </>
            )}
          </Flex>
          <div className={styles.commentBody}>
            <FormattedMetadata text={body} metadata={metadata} />
            {external_images?.length > 0 && (
              <PostImages
                metadata={metadata}
                images={external_images}
                post={{
                  body,
                  profile: { display_name, profile_image },
                  published_at_str,
                  published_at,
                }}
              />
            )}
          </div>
          <div className={styles.commentActions}>
            <CommentsActions
              permissions={permissions}
              votesCount={votes_sum}
              activeVote={vote?.vote_type}
              onAction={handleAction}
              counters={{
                id,
                votes_up,
                votes_down,
                reposts_count,
                comments_count,
              }}
            />
          </div>
          {isReplyOpen && (
            <div className={styles.commentForm}>
              <CommentForm
                autoFocus
                onCancel={() => setIsReplyOpen(false)}
                onSubmit={({ data }) => {
                  return handleAction("reply", data);
                }}
              />
            </div>
          )}
        </Flex>
      </Flex>
      {isLoading && <Spinner />}
      {error && <Typography.Text>{error}</Typography.Text>}
    </div>
  );
};

const memoizedThread = memo(ThreadComment);

export { memoizedThread as ThreadComment };
