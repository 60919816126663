import ROUTES from "@/routing/routes";
import * as $vars from "@/utils/constants/variables";
import cn from "@/utils/helpers/className";
import { formatDateTemplate } from "@/utils/helpers/date";
import { Flex, Tooltip, Typography } from "antd";
import { format } from "date-fns";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import React, { memo, useEffect, useState } from "react";
import { Link, generatePath } from "react-router-dom";

import { FormattedMetadata } from "../../formatted-metadata";
import { CommentForm } from "../../forms/comment-form";
import { Avatar } from "../../ui/avatar/avatar";
import { ProfileInfoTooltip } from "../../user-popover";
import PostActions from "../post-actions";
import PostImages from "../post-images/post-images";
import styles from "./post-card.module.scss";

const ReadMore = () => {
  return (
    <div className={styles.readMore}>
      <span className={styles.readMoreSpan}>
        Читати далі
        <ArrowDown2 size="16" color={$vars.colors.primaryColor} />
      </span>
    </div>
  );
};

const LongReadControls = ({ id }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const startElement = document.getElementById(`post-${id}-start`);
      const endElement = document.getElementById(`post-${id}-end`);

      if (startElement && endElement) {
        const startOffset = startElement.getBoundingClientRect().top;
        const endOffset = endElement.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        const totalDistance = endOffset - startOffset;
        const scrollDistance = window.scrollY - startElement.offsetTop;

        if (scrollDistance <= 0) {
          setProgress(0);
        } else if (scrollDistance >= totalDistance - windowHeight) {
          setProgress(100);
        } else {
          const scrollPercentage = (scrollDistance / totalDistance) * 100;
          setProgress(scrollPercentage);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [id]);

  return (
    <div className={styles.longreadBar}>
      <a href={`#post-${id}-start`}>
        <ArrowUp2 size="15" color="#7e7aff" />
        &nbsp; На початок
      </a>
      <a href={`#post-${id}-end`}>
        У кінець&nbsp;
        <ArrowDown2 size="15" color="#7e7aff" />
      </a>
      <div
        className={styles.longreadBarProgress}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};
const PostCard = ({
  post: {
    id,
    body,
    votes_up,
    votes_down,
    reposts_count,
    comments_count,
    profile,
    published_at_str,
    published_at,
    external_images,
    vote,
    permissions,
    views_count,
    metadata,
  },
  onAction,
  hasCommentForm,
  hasShadow,
}) => {
  const { display_name, profile_image, username } = profile;

  const [expanded, setExpanded] = useState(false);

  const onActionWithId = (type, metadata) => {
    return onAction({ id, type, metadata });
  };

  return (
    <div
      className={cn(
        styles.postWrap,
        published_at === null ? styles.notPublished : "",
      )}
      style={{
        ...(hasShadow && { boxShadow: $vars.boxShadow.lg }),
      }}
    >
      <Flex vertical gap={10}>
        <Flex className={styles.postHeader} gap={8} align={"top"}>
          <Link to={generatePath(ROUTES.otherProfile, { slag: username })}>
            <ProfileInfoTooltip username={username}>
              <Flex gap={8} align={"top"}>
                <Avatar size={40} url={profile_image?.small?.url} />
                <div className={styles.postAuthor}>{display_name}</div>
              </Flex>
            </ProfileInfoTooltip>
          </Link>
          <Tooltip
            title={
              published_at?.length && format(published_at, formatDateTemplate)
            }
          >
            <Link
              className={styles.postDateTime}
              to={generatePath(ROUTES.otherProfile, { slag: username })}
            >
              {published_at_str}
            </Link>
          </Tooltip>
        </Flex>
        {body && (
          <div>
            <Typography.Paragraph className={cn(styles.postBody, "post-body")}>
              <section id={`post-${id}-start`} />
              <FormattedMetadata
                text={expanded ? body : body.slice(0, 4000)}
                metadata={metadata}
              />
              {body.length > 400 && (
                <span onClick={() => setExpanded(!expanded)}>
                  {!expanded && (
                    <ReadMore
                      style={{
                        userSelect: "none",
                      }}
                    />
                  )}
                </span>
              )}
              {body.length > 4000 && expanded && <LongReadControls id={id} />}
            </Typography.Paragraph>
          </div>
        )}
        {external_images?.length > 0 && (
          <PostImages
            metadata={metadata}
            images={external_images}
            post={{
              body,
              profile: { display_name, profile_image },
              published_at_str,
              published_at,
            }}
          />
        )}
        <PostActions
          permissions={permissions}
          activeVote={vote?.vote_type}
          votesCount={vote.vote_value}
          onAction={onActionWithId}
          counters={{
            id,
            votes_up,
            votes_down,
            reposts_count,
            comments_count,
            views_count,
          }}
        />
        {hasCommentForm && (
          <div className={styles.postComments}>
            <CommentForm
              onSubmit={({ data }) => onActionWithId("reply", data)}
              autoFocus={false}
            />
            <section id={`post-${id}-end`} />
          </div>
        )}
      </Flex>
    </div>
  );
};

const PostCardMemo = memo(PostCard);
export { PostCardMemo as PostCard };
