import { Flex } from "antd";
import styles from "./register-buttons.module.scss";

import IconButton from "./components/icon-button/index.js";
import { SocialButtonsList } from "./index.js";

const RegisterButtons = () => {
  return (
    <Flex vertical gap={32}>
      <div className={styles.registerCol}>
        {SocialButtonsList.map(({ id, icon, title, disabled }) => (
          <IconButton key={id} title={title} icon={icon} disabled={disabled}  />
        ))}
      </div>
      <div className={styles.registerDescr}>Реєструючись, ви погоджуєтеся з Умовами обслуговування та Політикою конфіденційності, включаючи Використання файлів cookie.</div>
    </Flex>
  );
};

export default RegisterButtons;
