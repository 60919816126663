import { format } from "date-fns";
import React from "react";
import { Link, generatePath } from "react-router-dom";

import ROUTES from "../../../../routing/routes";
import { Avatar } from "../../avatar/avatar";
import { NotificationCard } from "../../cards/notification-card/notification-card";

const NotificationFollowerItem = ({ notificationData, onClick }) => {
  return (
    <Link
      onClick={onClick}
      to={generatePath(ROUTES.otherProfile, {
        slag: notificationData?.source?.username,
      })}
    >
      <NotificationCard
        icon={
          <Avatar
            size={40}
            url={notificationData?.source?.profile_image?.small?.url}
          />
        }
        subTitle={`Підписався на вас ${format(notificationData?.updated_at, "p")}`}
        title={notificationData?.source?.display_name}
      />
    </Link>
  );
};

export { NotificationFollowerItem };
