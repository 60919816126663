import { Avatar as AntdAvatar } from "antd";
import React from "react";
import { LuUser2 } from "react-icons/lu";

const Avatar = ({ url, size }) => {
  return (
    <AntdAvatar
      shape="square"
      size="large"
      icon={url ? <img src={url} alt={"avatar"} width={size} /> : <LuUser2 />}
    />
  );
};

export { Avatar };
