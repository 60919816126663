import queryString from "query-string";

import api from "./axios";

export const getPosts = async ({ from_id, username }) => {
  const params = queryString.stringify(
    { from_id, username },
    { skipNull: true },
  );
  return await api.get(`/posts?${params}`);
};

export const updateVote = async ({ postId, voteType }) => {
  return await api.post(`/posts/${postId}/votes`, {
    vote_type: voteType,
  });
};

export const createPost = async (body) => {
  return await api.post("/posts", body);
};

export const removePost = async (id) => {
  return await api.delete(`/posts/${id}`);
};

export const getPostForEdit = async (id) => {
  return await api.get(`/posts/${id}/edit`);
};

export const updatePost = async (id, body) => {
  return await api.patch(`/posts/${id}`, body);
};

export const getPostById = async (id) => {
  return await api.get(`/posts/${id}`);
};
