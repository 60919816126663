import {isMobile} from 'react-device-detect';
import {LinkMobilePreview} from "../link-mobile-preview/link-mobile-preview";
import {LinkDesktopPreview} from "../link-desktop-preview/link-desktop-preview";

const LinkPreview = ({ url, title, description, external_image }) => {
  return (
    <>
      {isMobile && (
        <LinkMobilePreview
          url={url}
          title={title}
          description={description}
          external_image={external_image?.url}
        />
      )}
      {!isMobile && (
        <LinkDesktopPreview
          url={url}
          title={title}
          description={description}
          external_image={external_image?.url}
        />
      )}
    </>
  )
};

export {LinkPreview};
