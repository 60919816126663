import { api } from "./api";

export const userApi = api.injectEndpoints({
  tagTypes: ["notifications"],
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (from_id) => {
        const url = from_id
          ? `profile/events?from_id=${encodeURIComponent(from_id)}`
          : "profile/events";

        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["notifications"],
    }),
  }),
});

export const { useLazyGetNotificationsQuery } = userApi;
