import styles from "./link-mobile-preview.module.scss";
import {Button, Modal} from "antd";
import {Link} from "react-router-dom";
import {useState} from "react";

export const LinkMobilePreview = ({url, title, description, external_image}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
        <span className={styles.link} onClick={showModal}>
          {title}
        </span>
      <Modal
        title={null}
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
        className={styles.linkPreviewModal}
        width={"100%"}
        transitionName=""
        closable={false}
      >
        <div className={styles.modalImage}>
          <img src={external_image} alt={title}/>
        </div>
        <div className={styles.modalContent}>
          <div className={styles.modalTitle}>{title}</div>
          <div className={styles.modalDescription}>{description}</div>
        </div>
        <div className={styles.modalControls}>
          <Button
            size="large"
            className={styles.modalButton}
            onClick={handleCancel}
          >
            Назад
          </Button>
          <Button
            as={Link}
            type="primary"
            size="large"
            className={styles.modalButton}
            href={url}
            target="_blank"
          >
            Перейти
          </Button>
        </div>
      </Modal>
    </>
  )
}