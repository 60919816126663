import styles from "./image-more.module.scss";
import { ReactComponent as GalleryIcon } from "../../../../../assets/icons/post/gallery.svg"

const ImageMore = ({ moreCount }) => {
  return (
    <div className={styles.imageMore}>
      <GalleryIcon/>
      <div className={styles.imageMoreLabel}>{moreCount}</div>
    </div>
  );
};

export { ImageMore };
