import { Divider, Flex } from "antd";

import IconButton from "./components/icon-button/index.js";
import { SocialButtonsList } from "./index.js";

const LoginButtons = () => {
  return (
    <Flex vertical gap={16} align={"center"}>
      <Divider plain>або</Divider>
      {SocialButtonsList.map(({ id, icon, title, disabled }) => (
        <IconButton key={id} title={title} icon={icon} disabled={disabled} />
      ))}
    </Flex>
  );
};

export default LoginButtons;
