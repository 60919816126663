import {
  useGetOtherProfileQuery,
  useGetSelfProfileQuery,
} from "@/features/api/user-api";
import ROUTES from "@/routing/routes";
import { Avatar, Flex } from "antd";
import React from "react";
import { LuUser2 } from "react-icons/lu";
import { Link, generatePath } from "react-router-dom";

import { OtherProfileActionsButton } from "../../profile/other-profile-actions-button";
import { Spinner } from "../../ui/spinner";
import styles from "./popover-user.module.scss";

const PopoverUser = ({ slug }) => {
  const { data: profile, isFetching } = useGetOtherProfileQuery(slug);
  const { data: selectedProfile } = useGetSelfProfileQuery();
  return (
    <div onClick={(e) => e.stopPropagation()}>
      {isFetching && <Spinner />}
      {!isFetching && profile && (
        <div className={styles.popover}>
          <Link
            to={generatePath(ROUTES.otherProfile, { slag: profile?.username })}
          >
            <Flex gap={8} style={{ marginBottom: "8px" }}>
              <Avatar
                shape="square"
                size={40}
                icon={
                  profile?.profile_image?.small?.url ? (
                    <img
                      src={profile?.profile_image?.small?.url}
                      alt="avatar"
                    />
                  ) : (
                    <LuUser2 />
                  )
                }
              />

              <Flex vertical>
                <div className={styles.popoverName}>
                  {profile?.display_name}
                </div>
                <div className={styles.popoverUsername}>
                  @{profile?.username}
                </div>
              </Flex>
            </Flex>
          </Link>
          <Flex className={styles.popoverStats}>
            <div className={styles.popoverStatItem}>
              <div className={styles.popoverStatValue}>
                {profile?.posts_count}
              </div>
              <div className={styles.popoverStatTitle}>дописи</div>
            </div>
            <div className={styles.popoverStatItem}>
              <div className={styles.popoverStatValue}>
                {profile?.followers_count}
              </div>
              <div className={styles.popoverStatTitle}>читачі</div>
            </div>
          </Flex>
          {selectedProfile?.username !== profile?.username && (
            <Flex gap={8}>
              <OtherProfileActionsButton
                username={profile?.username}
                relation={profile?.relations}
              />
            </Flex>
          )}
        </div>
      )}
    </div>
  );
};

export default PopoverUser;
