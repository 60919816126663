import React, { useEffect, useState } from "react";

import ImageGrid from "../../ui/image-grid";
import ModalImageUserInfo from "../../ui/modal-image-user-info";
import { ImageModal } from "../../ui/modals/image-modal";

const PostImages = ({ images, post, metadata }) => {
  const [openedImage, setOpenedImage] = useState(null);

  const onNavigation = (e) => {
    if (!e) {
      setOpenedImage(null);
    } else {
      setOpenedImage(e);
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      setOpenedImage(null);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (openedImage) {
      window.history.pushState(null, null, window.location.pathname);
    }
  }, [openedImage]);

  return (
    <>
      {openedImage && (
        <ImageModal
          infoSection={
            <ModalImageUserInfo
              metadata={metadata}
              body={post.body}
              name={post.profile.display_name}
              avatar={post.profile.profile_image?.small?.url}
              date={post.published_at_str}
            />
          }
          images={images}
          startFromIndex={openedImage?.index}
          onNavigation={onNavigation}
        />
      )}
      <ImageGrid
        images={images}
        onImageClick={({ index, image }) =>
          post?.published_at && setOpenedImage({ index, image })
        }
      />
    </>
  );
};

export default PostImages;
