import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profile: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.profile = action.payload;
    },
    resetUserData: (state) => {
      state.profile = null;
    },
  },
});

export const selectIsLoggedIn = (state) => state?.user?.profile?.username;

export const { actions, reducer } = userSlice;
