import { api } from "./api";

export const postsApi = api.injectEndpoints({
  tagTypes: ["report"],
  endpoints: (builder) => ({
    reportPostById: builder.mutation({
      query: ({ postId, data }) => ({
        url: `/posts/${postId}/abuse_reports`,
        method: "POST",
        body: {
          reason_type: data.reason_type,
          reason: data.reason,
          block: data.block,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useReportPostByIdMutation } = postsApi;
