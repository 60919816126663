import React, { useCallback, useEffect, useRef, useState } from "react";

import iconMap from "./icon-map";
import styles from "./svg-icon.module.scss";

const SvgIcon = ({ name, className, width, height }) => {
  const [Icon, setIcon] = useState(null);
  const iconRef = useRef(null);

  const loadIcon = useCallback(async () => {
    if (!name) return;

    try {
      const IconComponent = (await iconMap[name]()).ReactComponent;
      IconComponent && setIcon(() => IconComponent);
    } catch (error) {
      console.error(`Error loading icon at path: ${name}`, error);
    }
  }, [name]);

  useEffect(() => {
    loadIcon();
  }, [loadIcon]);

  useEffect(() => {
    if (iconRef.current) {
      iconRef.current.style.setProperty(
        "--width",
        `${width || iconRef.current.clientWidth}px`,
      );
      iconRef.current.style.setProperty(
        "--height",
        `${height || iconRef.current.clientHeight}px`,
      );
    }
  }, [width, height]);

  return (
    <>
      {Icon && <Icon ref={iconRef} className={`${styles.icon} ${className}`} />}
    </>
  );
};

export default SvgIcon;
