import { Profile2User } from "iconsax-react";
import React from "react";

import { getFollowersMessage } from "../../../../utils/helpers/string-transform";
import { ProfileInfoTooltip } from "../../../user-popover";
import { GenericNotificationGroupList } from "../generic-notification-group-list";
import { NotificationFollowerItem } from "../notification-follower-item";

const FollowersList = ({ notifications, onClick }) => {
  return (
    <GenericNotificationGroupList
      list={notifications}
      title={"Нові підписки"}
      subTitle={getFollowersMessage(notifications?.length)}
      icon={<Profile2User size="24" />}
      renderItem={(follower) => (
        <ProfileInfoTooltip
          key={follower.id}
          username={follower?.source?.username}
        >
          <NotificationFollowerItem
            onClick={onClick}
            notificationData={follower}
          />
        </ProfileInfoTooltip>
      )}
    />
  );
};

export { FollowersList };
