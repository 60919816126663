import { Image, MessageText, VideoPlay } from "iconsax-react";

import styles from "./file-upload-tooltip.module.scss";

const items = [
  {
    icon: <VideoPlay size="20" color="#7E7AFF" variant="Bold" />,
    title: "Відео",
    description: (
      <>
        Максимальна кількість відео, які публікується - 1 шт. <br /> Відео може
        бути не більше <b>100 мб</b> та тривалістю до <br /> 5 хвилин.
      </>
    ),
  },
  {
    icon: <Image size="20" color="#7E7AFF" variant="Bold" />,
    title: "Фото",
    description: (
      <>
        Максимальна кількість зображень без відео - 10. <br /> 1 зображення має
        містити не більше <b>10 мб</b>.
      </>
    ),
  },
  {
    icon: <MessageText size="20" color="#7E7AFF" variant="Bold" />,
    title: "Символи",
    description: (
      <>
        Максимально може бути опубліковано - <b>32000</b> текстових <br />{" "}
        символів
      </>
    ),
  },
];

const PopupTooltipItem = ({ icon, title, description }) => {
  return (
    <div className={styles.popupTooltip__items__item}>
      <div className={styles.popupTooltip__items__item__wrapper}>
        <div className={styles.popupTooltip__items__item__icon}>{icon}</div>
        <div className={styles.popupTooltip__items__item__group}>
          <p className={styles.popupTooltip__items__item__group__title}>
            {title}
          </p>
        </div>
      </div>
      <p className={styles.popupTooltip__items__item__group__description}>
        {description}
      </p>
    </div>
  );
};

const FileUploadTooltip = () => {
  return (
    <div className={styles.popupTooltip}>
      <div className={styles.popupTooltip__items}>
        {items.map((item, index) => (
          <PopupTooltipItem key={item.title + index} {...item} />
        ))}
      </div>
    </div>
  );
};

export default FileUploadTooltip;
