import { Input, notification } from "antd";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";

import useEnterSubmit from "../../../hooks/use-enter-submit";
import usePasteHandler from "../../../hooks/use-paste-handler";
import {
  getPreSignedUrl,
  uploadToPresignedUrl,
} from "../../../services/upload-service";
import cn from "../../../utils/helpers/className.js";
import { getAllErrors } from "../../../utils/helpers/string-transform";
import { AttachmentsList } from "../../ui/attachments-list";
import { CommentFormFooter } from "../../ui/comment-form-footer";
import { DragAndDropWrapper } from "../../ui/drag-and-drop-wrapper";
import styles from "./comment-form.module.scss";

const CommentForm = ({ post, onSubmit, autoFocus }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [isActive, setIsActive] = useState(false); // Added isActive state
  const uploadInputRef = useRef(null);
  const formRef = useRef(null);
  const [notificationApi, contextHolder] = notification.useNotification();

  const onUploadPercentChange = (id, progress) => {
    setAttachments((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            process: progress,
          };
        }
        return item;
      });
    });
  };

  const createAttachmentStateItem = async (file) => {
    try {
      const name = file.name;
      const { url, fields } = await getPreSignedUrl(name);
      setAttachments((prev) => [
        ...prev,
        {
          id: fields.key,
          file,
          process: 0,
        },
      ]);

      uploadToPresignedUrl(url, file, fields, (progress) =>
        onUploadPercentChange(fields.key, progress),
      );
    } catch (e) {
      getAllErrors(e.response.data).forEach((item) => {
        notificationApi.error({
          message: item,
        });
      });
    }
  };

  const onAddFile = async (e) => {
    const files = e.target.files;
    for await (const file of files) {
      await createAttachmentStateItem(file);
    }
    e.target.value = null;
  };

  const onUploadClick = () => {
    uploadInputRef.current.click();
  };

  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      ...values,
      files: attachments.map((item) => item.id),
    };
    await onSubmit({ data })
      .then((res) => {
        if (!res?.payload?.errors?.length) {
          resetForm();
          setAttachments([]);
        } else {
          res?.payload?.errors.forEach((item) => {
            notificationApi.error({
              message: item,
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePaste = (event) => {
    if (!isActive) return;
    const items = event.clipboardData.items;
    let handled = false;

    for (const item of items) {
      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file) {
          createAttachmentStateItem(file);
          handled = true;
        }
      }
    }

    if (!handled) {
      const pastedText = event.clipboardData.getData("text/plain");
      if (pastedText && !formRef?.current?.values?.body && formRef?.current) {
        formRef.current.setFieldValue("body", pastedText);
        event.preventDefault();
      }
    }
  };

  usePasteHandler(handlePaste, formRef);
  useEnterSubmit(() => {
    if (isActive) {
      formRef.current.submitForm();
    }
  });

  useEffect(() => {
    if (post?.external_images?.length) {
      setAttachments(post?.external_images);
    }
  }, [post]);

  return (
    <>
      {!isOpen && (
        <Input.TextArea
          onClick={() => setIsOpen(true)}
          onFocus={() => {
            setIsOpen(true);
            setIsActive(true);
          }}
          onBlur={() => setIsActive(false)}
          size={"middle"}
          autoSize={true}
          value={""}
          autoFocus={autoFocus}
          className={styles.commentForm}
          placeholder="Написати коментар"
        />
      )}
      {contextHolder}
      {isOpen && (
        <DragAndDropWrapper onDropFile={createAttachmentStateItem}>
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={{
              body: post?.body || "",
            }}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form
                className={cn(
                  styles.form,
                  !attachments.length && styles.noAttachments,
                )}
              >
                <div className={cn(styles.formPostWrap)}>
                  <Field
                    name="body"
                    render={({ field }) => (
                      <Input.TextArea
                        autoFocus
                        className={styles.formTextarea}
                        {...field}
                        autoSize={true}
                        placeholder="Написати коментар"
                        onFocus={() => setIsActive(true)}
                        onBlur={() => setIsActive(false)}
                      />
                    )}
                  />
                  <div>
                    <input
                      ref={uploadInputRef}
                      type="file"
                      onChange={onAddFile}
                      multiple
                      style={{ display: "none" }}
                    />
                    <AttachmentsList
                      attachments={attachments}
                      onRemoveAttachment={(image) => {
                        setAttachments((prev) =>
                          prev.filter((item) => item.id !== image),
                        );
                      }}
                    />
                  </div>
                </div>
                <CommentFormFooter
                  onCancel={() => setIsOpen(false)}
                  onUploadClick={onUploadClick}
                  isSubmitDisabled={
                    !values.body.trim() && attachments.length === 0
                  }
                />
              </Form>
            )}
          </Formik>
        </DragAndDropWrapper>
      )}
    </>
  );
};

export { CommentForm };
