import { Button, Modal } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";

import { Start } from "../../../auth/start";
import { remove } from "../../../../utils/helpers/local-storage";

import styles from "./login-modal.module.scss";

const LoginModal = () => {
  const [type, setType] = useState("login");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
    remove("accessToken");
    remove("refreshToken");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button as={Link} to={"/start"} onClick={showModal}>
        Увійти
      </Button>
      <Modal
        width={992}
        title={null}
        open={isModalOpen}
        footer={null}
        className="login-modal"
        onCancel={handleCancel}
        maskClosable={false}
      >
        <Start type={type} onChangeType={setType} />
      </Modal>
    </>
  );
};

export { LoginModal };
